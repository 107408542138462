<template>
  <v-container fluid pa-0 class="">
    <v-row align="center" justify="center" style="height:100vh" dense>
      <v-col cols="6">
        <v-card elevation="2">
          <v-card-title class="box white--text">Iniciar sesión<v-spacer></v-spacer><v-icon color="white">mdi-alien</v-icon></v-card-title>
          <v-form @submit.prevent="submit">
            <v-card-text class="pt-6 pb-0">
              <v-text-field label="e-mail" outlined append-icon="mdi-at" v-model="usuario.email"></v-text-field>
              <v-text-field label="Contraseña" outlined append-icon="mdi-key-outline" v-model="usuario.password" type="password" autocomplete="on"></v-text-field>
            </v-card-text>
            <v-card-actions  class="pt-0">
              <v-spacer></v-spacer>
              <v-btn @click="logIn(usuario)" color="deep-purple lighten-2" text>Acceder</v-btn>
            </v-card-actions>
            <span v-if="error">{{ error }}</span>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-snackbar v-model="snackbar">
    </v-snackbar> -->
  </v-container>
</template>

<script>
// import {db} from '../main'
import { mapActions, mapState } from 'vuex'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

export default {
  name: 'Login',
  data() {
    return{
      usuario: {
        email: "",
        password: ""
      },
    }
  },
  methods: {
    ...mapActions(['logIn'])
  },
  computed: {
    ...mapState(['error', 'snackbar']),
  },

  created() {
    if (firebase.auth().currentUser) {
      this.$router.push({ path: '/' }).catch(()=>{})
      // this.$router.push("/panel").catch(()=>{});
    }
  }
}
</script>

<style scoped>
.box {
  background: #7a77d0;
}
</style>